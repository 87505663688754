<template>
  <div id="mainpricacy" class="mt-30">
    <div class="top__header">
      마케팅 수신 동의
    </div>
    <div class="content-list pc-only">
      <div class="top__content-box">
        마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집 이용을 거부하실 수 있으며, 거부 시에도 낭만남해 서비스를 이용하실 수 있으나, 동의를 구한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.
        <br>
      </div>
      <div class="flex">
          <div class="content-box">
           <div class="title" @click="toggleText('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>마케팅 정보 활용 동의 (선택)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'first'">
              <b>목적</b> : 낭만남해가 제공하는 이용자 맞춤형 서비스 및 상품추천, 프로모션 안내, 이벤트 참여 안내<br>
              <b>수집항목</b> : 이메일 주소, 휴대전화번호<br>
              <b>보유 및 이용기간</b> : 회원 탈퇴 시 또는 동의 철회 시<br>
            </div>
          </div>
      </div>
    </div>
    <div class="content-list mb-only">
      <div class="top__content-box">
        마케팅 정보 수신 여부 및 마케팅을 위한 개인정보 수집 이용을 거부하실 수 있으며, 거부 시에도 낭만남해 서비스를 이용하실 수 있으나, 동의를 구한 경우 각종 소식 및 이벤트 참여에 제한이 있을 수 있습니다.
        <br>
      </div>
      <div class="flex">
          <div class="content-box">
           <div class="title" @click="toggleText('first')">
            <div class="title-icon"><img src="../../assets/img/mobile/main-privacy__icon.svg"></div>
            <p>마케팅 정보 활용 동의 (선택)</p>
            <i :class="['el-icon-arrow-up', { 'rotate-icon': showtext === 'first' }]"></i>
            </div>
            <div class="text" v-show="showtext === 'first'">
              <b>목적</b> : 낭만남해가 제공하는 이용자 맞춤형 서비스 및 상품추천, 프로모션 안내, 이벤트 참여 안내<br>
              <b>수집항목</b> : 이메일 주소, 휴대전화번호<br>
              <b>보유 및 이용기간</b> : 회원 탈퇴 시 또는 동의 철회 시<br>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showtext: 'first',
    };
  },
  methods: {
    toggleText(text) {
      this.showtext = (this.showtext === text) ? null : text;
    },
  },
};
</script>
